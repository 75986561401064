import {
  faCheck,
  faCog,
  faMinus,
  faPlus
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import Spinner from "common/other/Spinner"
import {
  organizationPlanDate,
  organizationPlanInterval
} from "billing/AdditionalFeatures"
import { useEffect, useState } from "react"
import { Button, Card, Form, InputGroup, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import {
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import {
  billingCurrencySelector,
  pricingState,
  subscriptionItemsState
} from "."
import { useNavigate } from "react-router-dom"
import { invoicesState } from "./Overview"
import { pricePreviewBillingCycle } from "./SubscriptionPlans"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import ActiveLicensesBar from "./ActiveLicensesBar"

const timbeterProSelector = selector({
  key: "timbeterPro",
  get: ({ get }) =>
    get(subscriptionItemsState).find(
      item => item.package_id === "pro_subscription"
    )
})

export const subscriptionExistsSelector = selector({
  key: "subscriptionExists",
  get: ({ get }) => get(subscriptionStatusSelector) !== null
})

export const subscriptionStatusSelector = selector({
  key: "subscriptionStatus",
  get: ({ get }) => get(currentOrganizationState)?.subscription?.status
})

export const subscriptionCanceledSelector = selector({
  key: "subscriptionCanceled",
  get: ({ get }) =>
    get(currentOrganizationState)?.subscription?.cancel_at_period_end
})

export default function TimbeterPro() {
  const fetch = useFetch()
  const [showModal, setShowModal] = useState(false)
  const [loadingPricePreview, setLoadingPricePreview] = useState(false)
  const [confirmingPayment, setConfirmingPayment] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [pricePreview, setPricePreview] = useState()
  // const [trial, setTrial] = useState(false)
  const pricePreviewBilling = useRecoilValue(pricePreviewBillingCycle)
  const orgBilling = useRecoilValue(organizationPlanInterval)
  const billing = orgBilling || pricePreviewBilling
  const pricing = useRecoilValue(pricingState)
  const [subscriptionItems, setSubscriptionItems] = useRecoilState(
    subscriptionItemsState
  )
  const subscriptionExists = useRecoilValue(subscriptionExistsSelector)
  const planExists = useRecoilValue(timbeterProSelector)
  const billingDate = useRecoilValue(organizationPlanDate)
  const [invoice, setInvoice] = useState()
  const setInvoices = useSetRecoilState(invoicesState)
  const navigate = useNavigate()
  const billingCurrency = useRecoilValue(billingCurrencySelector)

  function addPlan() {
    if (subscriptionExists) {
      setConfirmingPayment(true)
      if (planExists) {
        // TODO: fetch("/subscription/items")
        fetch("/subscription/items", {
          method: "PATCH",
          body: {
            package_id: "pro_subscription",
            quantity
          }
        }).then(response => {
          setInvoice(response)
          setInvoices(invoices => [response, ...invoices])
          setConfirmingPayment(false)
        })
      } else {
        fetch("/subscription/items", {
          method: "POST",
          body: {
            package_id: "pro_subscription",
            quantity
          }
        }).then(response => {
          setInvoice(response)
          setInvoices(invoices => [response, ...invoices])
          setConfirmingPayment(false)
        })
      }
    } else {
      fetch("/checkout/subscription", {
        method: "POST",
        body: {
          period: billing === "yearly" ? 12 : 1,
          line_items: [
            {
              quantity,
              package_id: "pro_subscription"
            }
          ]
        }
      }).then(response => {
        window.location.assign(response.redirect_url)
      })
    }
  }

  useEffect(() => {
    if (
      showModal &&
      (quantity > planExists?.quantity || (subscriptionExists && !planExists))
    ) {
      setPricePreview()
      setLoadingPricePreview(true)
      fetch("/subscription/items/preview", {
        method: "POST",
        body: {
          package_id: "pro_subscription",
          quantity
        }
      })
        .then(response => {
          setPricePreview(response)
        })
        .finally(() => {
          setLoadingPricePreview(false)
        })
    }
    // eslint-disable-next-line
  }, [showModal, quantity])

  function cancelPlan() {
    if (window.confirm(t`Are you sure?`)) {
      fetch("/subscription/items/pro_subscription", {
        method: "DELETE"
      })
        .then(response => {
          // console.log(response)
          setShowModal(false)
          setSubscriptionItems(items =>
            items.map(item =>
              item.package_id === "pro_subscription"
                ? {
                    ...item,
                    cancelled: true
                  }
                : item
            )
          )
          toast.success(<Trans>Canceled plan</Trans>)
        })
        .catch(() => {
          toast.error(<Trans>Failed to cancel plan</Trans>)
        })
    }
  }

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        {!invoice ? (
          <>
            <Modal.Header className="pb-0" closeButton>
              <Modal.Title>
                <Trans>Timbeter Pro</Trans>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
              <ul className="text-start list-unstyled small bg-light px-4 py-3 rounded">
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  <Trans>Unlimited measurements</Trans>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  <Trans>Diameter, contour, density & truck measurements</Trans>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  <Trans>Measurement sharing</Trans>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  <Trans>Measurement editing</Trans>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  <Trans>Panorama</Trans>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} className="me-2" />
                  <Trans>Pro storage module</Trans>
                </li>
              </ul>
              <Form.Group className="mb-2">
                <Form.Label>
                  <Trans>Device licenses</Trans>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control value={quantity} readOnly />
                  <Button onClick={() => setQuantity($ => $ + 1)}>
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  {!planExists && (
                    <Button
                      disabled={quantity === 1}
                      onClick={() => setQuantity($ => $ - 1)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  )}
                  {/* <Button
                    variant="outline-primary"
                    disabled={quantity === 1}
                    onClick={() => setQuantity(quantity - 1)}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button> */}
                </InputGroup>
                {(quantity > planExists?.quantity ||
                  (subscriptionExists && !planExists)) && (
                  <div className="bg-light px-3 py-2 rounded mt-2">
                    <p className="small text-muted">
                      <Trans>Total</Trans>
                    </p>
                    {pricePreview ? (
                      <span className="fw-semibold">
                        {billingCurrency}
                        {pricePreview.amount_due}
                      </span>
                    ) : (
                      <Spinner variant="dark" />
                    )}
                  </div>
                )}
              </Form.Group>
              {/* {!subscriptionExists && (
                <Form.Group className="mb-2">
                  <Form.Check
                    checked={trial}
                    onChange={$ => setTrial($.target.checked)}
                    type="checkbox"
                    label="Trial"
                  />
                </Form.Group>
              )} */}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="w-full position-relative"
                disabled={
                  loadingPricePreview ||
                  confirmingPayment ||
                  quantity === planExists?.quantity
                }
                onClick={addPlan}
              >
                <span className="position-absolute start-0 align-items-center ps-3 opacity-25">
                  {confirmingPayment && <Spinner />}
                </span>
                <span>
                  {subscriptionExists ? (
                    planExists ? (
                      <Trans>Confirm</Trans>
                    ) : (
                      <Trans>Start plan</Trans>
                    )
                  ) : (
                    <Trans>Checkout</Trans>
                  )}
                </span>
              </Button>
              {planExists && (
                <Button
                  onClick={cancelPlan}
                  variant="outline-secondary"
                  className="w-full border-0"
                >
                  <Trans>Cancel plan</Trans>
                </Button>
              )}
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header className="pb-0" closeButton>
              <Modal.Title>
                <Trans>Order received</Trans>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mb-3">
                <Trans>
                  A new pending invoice has been generated and is awaiting
                  payment.
                </Trans>
              </p>
              <Button
                onClick={() => navigate("/billing/invoices")}
                variant="info"
                className="w-full"
              >
                <Trans>Invoices</Trans>
              </Button>
            </Modal.Body>
          </>
        )}
      </Modal>

      <Card className="rounded-4 h-100">
        <ActiveLicensesBar plan={planExists} billingDate={billingDate} />
        <Card.Body className="d-flex flex-column">
          <p className="fw-semibold py-2">
            <img
              src="/logos/timber.svg"
              width="28"
              className="rounded me-2"
              alt="Timbeter"
            />
            <Trans>Timbeter</Trans> Pro
          </p>
          <div className="py-2 flex-grow-1 lh-1">
            <Card.Title className="display-6 fw-semibold mb-0 lh-1">
              <span className="fs-5">{billingCurrency}</span>
              {billing === "monthly"
                ? Math.round(pricing.pro_subscription.monthly_price)
                : Math.round(pricing.pro_subscription.yearly_price / 12)}
            </Card.Title>
            <span className="text-black-50 opacity-75 fw-normal small">
              <Trans>device/month</Trans>
            </span>
          </div>
          {billing === "yearly" ? (
            <p className="text-muted small mb-4">
              <Trans>Billed as</Trans> {billingCurrency}
              {Math.round(pricing.pro_subscription.yearly_price)}{" "}
              <Trans>per year</Trans>
            </p>
          ) : (
            <p className="text-muted small mb-4">
              <Trans>Billed as</Trans> {billingCurrency}
              {Math.round(pricing.pro_subscription.monthly_price)}{" "}
              <Trans>per month</Trans>
            </p>
          )}
          {planExists ? (
            <Button
              variant="secondary"
              className="w-full rounded-pill"
              disabled={planExists.cancelled}
              onClick={() => {
                setQuantity(
                  subscriptionItems.find(
                    item => item.package_id === "pro_subscription"
                  ).quantity || 0
                )
                setShowModal(true)
              }}
            >
              <FontAwesomeIcon icon={faCog} className="me-2 opacity-50" />
              <Trans>Plan settings</Trans>
            </Button>
          ) : (
            <Button
              onClick={() => setShowModal(true)}
              className="w-100 rounded-pill"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2 opacity-50" />
              <Trans>Add plan</Trans>
            </Button>
          )}
        </Card.Body>
      </Card>
    </>
  )
}
