import ReactSelect from "react-select"
import CreatableSelect from "react-select/creatable"

export default function Select({
  isMulti,
  value,
  onChange,
  options,
  required,
  creatable,
  isSearchable,
  disabled,
  placeholder,
  menuPlacement,
  inputValue,
  onInputChange
}) {
  return creatable ? (
    <CreatableSelect
      inputValue={inputValue}
      onInputChange={onInputChange}
      isDisabled={disabled}
      menuPlacement={menuPlacement}
      placeholder={placeholder || ""}
      // isDisabled={!options}
      // isLoading={!options}
      isMulti={isMulti}
      value={value}
      onChange={onChange}
      // options={options}
      styles={customStyles}
      isRequired={required}
      isSearchable={isSearchable}
      components={{
        Menu: () => null
      }}
    />
  ) : (
    <ReactSelect
      isDisabled={disabled}
      menuPlacement={menuPlacement}
      placeholder={placeholder || ""}
      isLoading={!options}
      isMulti={isMulti}
      value={value}
      onChange={onChange}
      options={options}
      styles={customStyles}
      isRequired={required}
      isSearchable={isSearchable}
    />
  )
}

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    border: state.isFocused
      ? "1px solid #80caa2 !important"
      : "1px solid #ced4da !important",
    boxShadow: state.isFocused ? "0 0 0 0.25rem rgb(0 148 69 / 25%)" : "",
    borderRadius: "6px"
  }),
  multiValueLabel: styles => ({
    ...styles,
    margin: "0 .25rem",
    color: "rgb(48, 52, 56)",
    fontSize: "14px",
    fontFamily: "sans-serif !important",
    fontWeight: "bold"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    borderRadius: "6px",
    color: "rgb(48, 52, 56)",
    background: "gainsboro",
    padding: "0",
    margin: "7px 8px 6px 0",
    cursor: "pointer"
  }),
  multiValue: styles => ({
    ...styles,
    borderRadius: "6px",
    background: "whitesmoke",
    boxShadow: "0 0 0 1px lightgray"
  }),
  option: (styles, state) => ({
    background: state.isFocused ? "mediumseagreen" : "white",
    color: state.isFocused ? "white" : "rgb(48, 52, 56)",
    padding: ".25rem .5rem",
    cursor: "default"
  }),
  menu: styles => ({
    ...styles,
    boxShadow: "none",
    border: "1px solid #ced4da !important"
  }),
  indicatorSeparator: styles => ({
    display: "none"
  }),
  clearIndicator: styles => ({
    display: "none"
  })
}
