import "react-datepicker/dist/react-datepicker.css"
import { Button, Col, Form, Row, InputGroup } from "react-bootstrap"
import Select from "common/other/Select"
import DatePicker from "react-datepicker"
import useFetch from "common/hooks/useFetch"
import measurementTypes from "common/enums/measurementTypes"
import { storageStatuses } from "common/enums/storageStatuses"
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  filteredStoragesState,
  timberFilterState,
  storagesState,
  showAdditionalTimberFiltersState,
  markersState
} from "common/recoil/atoms"
import { Trans, t } from "@lingui/macro"
import TimberAdditionalFilters from "./TimberAdditionalFilters"
import { clearFilterSelector, storagesListQuery } from "common/recoil/selectors"
import { Suspense, useState } from "react"
import { searchableCustomFields } from "custom_fields/useCustomFieldState"
import StorageMarker from "./map/StorageMarker"
import { InputGroupSeparator } from "./TimberMeasurementRow"

export const timberFilteringState = atom({
  key: "timberFilteringState",
  default: false
})

export default function TimberFilters() {
  const setMarkers = useSetRecoilState(markersState)
  const searchableCustomFieldNames = useRecoilValue(searchableCustomFields)
  const fetch = useFetch()
  const [storageNameFilters, setStorageNameFilters] = useRecoilState(
    timberFilterState("storageNames")
  )
  const [measurementFromFilter, setMeasurementFromFilter] = useRecoilState(
    timberFilterState("from")
  )
  const [measurementToFilter, setMeasurementToFilter] = useRecoilState(
    timberFilterState("to")
  )
  const [takenAtFromFilter, setTakenAtFromFilter] = useRecoilState(
    timberFilterState("takenAtFrom")
  )
  const [takenAtToFilter, setTakenAtToFilter] = useRecoilState(
    timberFilterState("takenAtTo")
  )
  const [measurementCreatedFromFilter, setMeasurementCreatedFromFilter] =
    useRecoilState(timberFilterState("createdFrom"))
  const [measurementCreatedToFilter, setMeasurementCreatedToFilter] =
    useRecoilState(timberFilterState("createdTo"))
  const [measurementTypeFilters, setMeasurementTypeFilters] = useRecoilState(
    timberFilterState("measurementTypes")
  )
  const [measurementIdsFilter, setMeasurementIdsFilter] = useRecoilState(
    timberFilterState("measurementIds")
  )
  const [storageStatusFilters, setStorageStatusFilters] = useRecoilState(
    timberFilterState("storageStatuses")
  )
  const woodAssortmentFilters = useRecoilValue(
    timberFilterState("woodAssortments")
  )
  const storagesList = useRecoilValue(storagesListQuery)
  const minLengthFilter = useRecoilValue(timberFilterState("minLength"))
  const maxLengthFilter = useRecoilValue(timberFilterState("maxLength"))
  const minVolumeFilter = useRecoilValue(timberFilterState("minVolume"))
  const maxVolumeFilter = useRecoilValue(timberFilterState("maxVolume"))
  const woodTypeFilters = useRecoilValue(timberFilterState("woodTypes"))
  const woodQualityFilters = useRecoilValue(timberFilterState("woodQualities"))
  const deviceFilters = useRecoilValue(timberFilterState("devices"))
  const shipmentNumberFilter = useRecoilValue(
    timberFilterState("shipmentNumber")
  )
  const storages = useRecoilValue(storagesState)
  const setFilteredStorages = useSetRecoilState(filteredStoragesState)
  const [showAdditionalFilters, setShowAdditionalFilters] = useRecoilState(
    showAdditionalTimberFiltersState
  )
  const clearFilters = useSetRecoilState(clearFilterSelector)
  const [filtering, setFiltering] = useRecoilState(timberFilteringState)
  const [inputValue, setInputValue] = useState()

  // useEffect(() => {
  //   const oneYearAgo = new Date()
  //   oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
  //   setMeasurementFromFilter(oneYearAgo)
  // }, [setMeasurementFromFilter])
  // useEffect(() => {
  //   return () => clearFilters()
  // }, [clearFilters])

  return (
    <div className="d-flex flex-column gap-3">
      <Row>
        <Form.Group as={Col} md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Storage</Trans>
          </Form.Label>
          <Select
            placeholder={<Trans>All</Trans>}
            isMulti
            value={storageNameFilters}
            onChange={event => setStorageNameFilters(event)}
            options={storagesList?.map(storage => ({
              value: storage.id,
              label: storage.name
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Storage status</Trans>
          </Form.Label>
          <Select
            placeholder={<Trans>All</Trans>}
            isMulti
            value={storageStatusFilters}
            onChange={event => setStorageStatusFilters(event)}
            options={Object.entries(storageStatuses).map(([id, status]) => ({
              value: id,
              label: status
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Taken between</Trans>
          </Form.Label>
          <InputGroup style={{ flexWrap: "nowrap" }}>
            <DatePicker
              placeholderText="..."
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-start"
              selected={takenAtFromFilter}
              onChange={date => setTakenAtFromFilter(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              }}
            />
            <InputGroupSeparator />
            <DatePicker
              placeholderText={t`Today`}
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-end"
              selected={takenAtToFilter}
              onChange={date => setTakenAtToFilter(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Measured between</Trans>
          </Form.Label>
          <InputGroup style={{ flexWrap: "nowrap" }}>
            <DatePicker
              placeholderText="..."
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-start"
              selected={measurementFromFilter}
              onChange={date => setMeasurementFromFilter(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              }}
            />
            <InputGroupSeparator />
            <DatePicker
              placeholderText={t`Today`}
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-end"
              selected={measurementToFilter}
              onChange={date => setMeasurementToFilter(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Created between</Trans>
          </Form.Label>
          <InputGroup style={{ flexWrap: "nowrap" }}>
            <DatePicker
              placeholderText="..."
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-start"
              selected={measurementCreatedFromFilter}
              onChange={date => setMeasurementCreatedFromFilter(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              }}
            />
            <InputGroupSeparator />
            <DatePicker
              placeholderText={t`Today`}
              wrapperClassName="datepicker"
              dateFormat="dd.MM.yyyy"
              className="form-control no-focus cursor-pointer rounded-0 rounded-end"
              selected={measurementCreatedToFilter}
              onChange={date => setMeasurementCreatedToFilter(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" lg="6" className="mb-2">
          <Form.Label>
            <Trans>Measurement type</Trans>
          </Form.Label>
          <Select
            placeholder={<Trans>All</Trans>}
            isMulti
            value={measurementTypeFilters}
            onChange={event => setMeasurementTypeFilters(event)}
            options={Object.entries(measurementTypes).map(([id, type]) => ({
              value: id,
              label: type
            }))}
          />
        </Form.Group>
        <Form.Group as={Col} xs="12" className="mb-2">
          <Form.Label>
            <Trans>Measurement IDs</Trans>
          </Form.Label>
          <Select
            creatable={true}
            placeholder={<Trans>Enter measurement IDs</Trans>}
            isMulti
            inputValue={inputValue}
            value={measurementIdsFilter}
            onInputChange={(textInput, { action }) => {
              if (action === "input-change") {
                if (
                  textInput &&
                  !textInput.endsWith(",") &&
                  textInput.includes(",")
                ) {
                  const newValues = textInput
                    .trim()
                    .split(",")
                    .map($ => ({ label: $, value: $ }))
                  setMeasurementIdsFilter([
                    ...(measurementIdsFilter || []),
                    ...newValues
                  ])
                  setInputValue("")
                  return
                }
                if (textInput && textInput.endsWith(",")) {
                  const label = textInput.slice(0, -1)
                  const newValue = { label, value: label }
                  setMeasurementIdsFilter([
                    ...(measurementIdsFilter || []),
                    newValue
                  ])
                  setInputValue("")
                } else {
                  setInputValue(textInput)
                }
              }
            }}
            onChange={event => {
              console.log(event)
              setMeasurementIdsFilter(event)
              setInputValue("")
            }}
          />
        </Form.Group>
      </Row>
      {showAdditionalFilters && (
        <Suspense fallback>
          <TimberAdditionalFilters />
        </Suspense>
      )}
      <Col xs="12" className="d-flex gap-3">
        <Button
          variant="dark"
          type="submit"
          disabled={filtering}
          className="text-nowrap"
          onClick={filterStorages}
        >
          {filtering ? <Trans>Loading...</Trans> : <Trans>Search</Trans>}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
        >
          {showAdditionalFilters ? (
            <Trans>Hide additional filters</Trans>
          ) : (
            <Trans>Show additional filters</Trans>
          )}
        </Button>
        <Button
          variant="outline-secondary"
          disabled={filtering}
          onClick={() => {
            clearFilters()
            setMarkers(
              storages?.map((s, i) => {
                return <StorageMarker key={i} storage={s} />
              })
            )
          }}
        >
          <Trans>Clear</Trans>
        </Button>
      </Col>
    </div>
  )

  function filterStorages(event) {
    event && event.preventDefault()

    setFiltering(true)
    fetch(
      `/dashboard_filter?${new URLSearchParams({
        ...(storageNameFilters?.length && {
          storage_ids: storageNameFilters.map($ => $.value)
        }),
        ...(storageStatusFilters?.length && {
          storage_state_ids: storageStatusFilters.map($ => $.value)
        }),
        ...(measurementTypeFilters?.length && {
          measurement_type_ids: measurementTypeFilters.map($ => $.value)
        }),
        ...(woodTypeFilters?.length && {
          wood_type_ids: woodTypeFilters.map($ => $.value)
        }),
        ...(woodQualityFilters?.length && {
          wood_quality_ids: woodQualityFilters.map($ => $.value)
        }),
        ...(woodAssortmentFilters?.length && {
          wood_characteristic_ids: woodAssortmentFilters.map($ => $.value)
        }),
        ...(deviceFilters?.length && {
          device_ids: deviceFilters.map($ => $.value)
        }),
        ...(minVolumeFilter && { volume_range_start: minVolumeFilter }),
        ...(maxVolumeFilter && { volume_range_end: maxVolumeFilter }),
        ...(minLengthFilter && { length_range_start: minLengthFilter }),
        ...(maxLengthFilter && { length_range_end: maxLengthFilter }),
        ...(measurementFromFilter && {
          measured_at_start: new Intl.DateTimeFormat("et-EE").format(
            measurementFromFilter
          )
        }),
        ...(measurementToFilter && {
          measured_at_end: new Intl.DateTimeFormat("et-EE").format(
            measurementToFilter
          )
        }),
        ...(takenAtFromFilter && {
          taken_at_start: new Intl.DateTimeFormat("et-EE").format(
            takenAtFromFilter
          )
        }),
        ...(takenAtToFilter && {
          taken_at_end: new Intl.DateTimeFormat("et-EE").format(takenAtToFilter)
        }),
        ...(measurementCreatedFromFilter && {
          created_at_start: new Intl.DateTimeFormat("et-EE").format(
            measurementCreatedFromFilter
          )
        }),
        ...(measurementCreatedToFilter && {
          created_at_end: new Intl.DateTimeFormat("et-EE").format(
            measurementCreatedToFilter
          )
        }),
        ...(measurementIdsFilter && {
          measurement_ids: measurementIdsFilter.map($ => $.value)
        }),
        ...(shipmentNumberFilter && {
          shipment_number: shipmentNumberFilter
        }),
        measurements_included: true,
        ...searchableCustomFieldNames
      })}`
    )
      .then(response => {
        setFilteredStorages(response?.storages || [])
        setMarkers(
          response?.storages?.map((s, i) => {
            return <StorageMarker key={i} storage={s} />
          })
        )
      })
      .finally(() => setFiltering(false))
  }
}
