import {
  faArrowDown,
  faCheck,
  faTabletScreenButton
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Image, Modal } from "react-bootstrap"

export default function TimbeterStereo() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <Trans>Stereo Camera Solution</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled text-start small bg-light px-4 py-3 rounded">
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Increased Precision</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Streamlined Measurement Process</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Efficient Volume Calculation</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Automated Image Processing</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Reduced Labor Costs</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Handheld and Portable</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Improved Reliability</Trans>
            </li>
          </ul>
          <div className="justify-content-center d-flex">
            <Button
              variant="info"
              className="rounded-pill fw-medium px-3"
              href="/Stereo camera Solution.pdf"
              target="_blank"
            >
              <small>
                <Trans>Explore functions & specifications</Trans>
              </small>
            </Button>
          </div>
          <p className="text-black-50 my-4 fw-medium text-center">
            <Trans>To get a price estimate contact our Sales team below</Trans>{" "}
            <FontAwesomeIcon icon={faArrowDown} size="xs" />
          </p>
          <Button
            className="w-full rounded-pill"
            onClick={() => {
              window.Intercom(
                "showNewMessage",
                t`Hi! I'm interested in Stereo Camera Solution.`
              )
            }}
          >
            <Trans>Contact us</Trans>
          </Button>
        </Modal.Body>
      </Modal>

      <Card className="rounded-4 h-100">
        <Card.Body className="d-flex flex-column justify-content-between">
          <p className="fw-semibold py-2">
            <FontAwesomeIcon
              icon={faTabletScreenButton}
              size="xl"
              className="me-2"
            />
            <Trans>Stereo Camera Solution</Trans>
          </p>
          <div className="d-flex flex-row justify-content-center">
            <div className="align-content-center">
              <Image
                fluid
                src="/stereo_camera.png"
                className="rounded me-2"
                alt="Timbeter"
                // style={{maxWidth: 320, minWidth: 256 }}
                style={{ maxWidth: 256, minWidth: 128 }}
              />
            </div>
            <div className="align-content-center">
              <p className="text-muted fs-6 p-4">
                <small>
                  <Trans>
                    Experience unmatched precision and efficiency in log
                    measurement with Timbeter's innovative Stereo Camera system,
                    integrating stereometric technology and advanced algorithms
                    for reliable surface area analysis.
                  </Trans>
                </small>
              </p>
              <Button
                onClick={() => setShowModal(true)}
                className="rounded-pill px-4"
                variant="dark"
              >
                <Trans>Learn more</Trans>
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}
