import { Trans } from "@lingui/macro"

export default function ActiveLicensesBar({ plan, billingDate }) {
  return plan ? (
    <div className="m-2 bg-primary text-light rounded-3 fw-semibold opacity-75">
      {plan &&
        (plan.cancelled ? (
          <>
            <Trans>Active until</Trans>{" "}
            {new Date(billingDate).toLocaleDateString()}
          </>
        ) : (
          <>
            <Trans>Active</Trans> · {plan.quantity} <Trans>licenses</Trans>
          </>
        ))}
    </div>
  ) : null
}
