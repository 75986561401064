import { faArrowDown, faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t, Trans } from "@lingui/macro"
import { useState } from "react"
import { Button, Card, Modal } from "react-bootstrap"
import { selector, useRecoilValue } from "recoil"
import { subscriptionItemsState } from "."
import { organizationPlanDate } from "./AdditionalFeatures"
import ActiveLicensesBar from "./ActiveLicensesBar"

const timbeterEnterpriseSelector = selector({
  key: "timbeterEnterprise",
  get: ({ get }) =>
    get(subscriptionItemsState).find(
      item => item.package_id === "enterprise_subscription"
    )
})

export default function TimbeterEnterprise() {
  const planExists = useRecoilValue(timbeterEnterpriseSelector)
  const [showModal, setShowModal] = useState(false)
  const billingDate = useRecoilValue(organizationPlanDate)

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <Trans>Timbeter Enterprise</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled text-start small bg-light px-4 py-3 rounded">
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Unlimited measurements</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Diameter, contour, density & truck measurements</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Measurement sharing</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Measurement editing</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Panorama</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Diameter profiles</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Loading tool</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>QR code detection</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Manual measurement</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Custom fields</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Trainings</Trans>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="me-2" />
              <Trans>Enterprise storage module</Trans>
            </li>
          </ul>
          <p className="text-black-50 my-4 fw-medium text-center">
            <Trans>To get a price estimate contact our Sales team below</Trans>{" "}
            <FontAwesomeIcon icon={faArrowDown} size="xs" />
          </p>
          <Button
            className="w-full rounded-pill"
            onClick={() => {
              window.Intercom(
                "showNewMessage",
                t`Hi! I'm interested in Timbeter Enterprise.`
              )
            }}
          >
            <Trans>Contact us</Trans>
          </Button>
        </Modal.Body>
      </Modal>

      <Card className="rounded-4 border-0 h-100">
        <ActiveLicensesBar plan={planExists} billingDate={billingDate} />
        {/* <div className="bg-warning rounded-top small fw-semibold bg-gradient">
        Subscription
      </div> */}
        <Card.Body className="d-flex flex-column justify-content-between">
          <p className="fw-semibold py-2">
            <img
              src="/logos/timber.svg"
              width="28"
              className="rounded me-2"
              alt="Timbeter"
            />
            <Trans>Timbeter Enterprise</Trans>
          </p>
          <p className="text-muted fs-6 p-4 ">
            <Trans>
              For large forestry organizations seeking enterprise-grade digital
              timber measurements.
            </Trans>
          </p>
          {planExists ? (
            <Button
              variant="outline-secondary"
              className="w-full rounded-pill border-0"
              disabled
            >
              <Trans>Managed by Timbeter</Trans>
            </Button>
          ) : (
            <Button
              onClick={() => setShowModal(true)}
              className="rounded-pill"
              variant="dark"
            >
              <Trans>Learn more</Trans>
            </Button>
          )}
        </Card.Body>
      </Card>
    </>
  )
}
