import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, t } from "@lingui/macro"
import { PageItem, Pagination } from "react-bootstrap"
import { Suspense, useEffect, useState } from "react"
import {
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from "recoil"
import {
  selectedMeasurementsState,
  showMapState,
  markersState
} from "common/recoil/atoms"
import MeasurementMarker from "timber-measurement/map/MeasurementMarker"
import TimberMeasurementRow from "./TimberMeasurementRow"
import { querySelector } from "common/recoil/selectors"
import normalizeJson from "common/utils/normalizeJson"
import Loading from "common/other/Loading"
import { storageMapMeasurementsState } from "./map/StorageMarker"
import { useSearchParams } from "react-router-dom"

export const storageMeasurementsPageState = atomFamily({
  key: "storageMeasurementsPageState",
  default: 1
})

export const measurementsQuery = selectorFamily({
  key: "measurementsQuery",
  get:
    ({ pagination, storage }) =>
    ({ get }) => {
      // check if fetched from map and use that
      const storageMapMeasurements = get(
        storageMapMeasurementsState(storage.id)
      )
      if (storageMapMeasurements) {
        return {
          meta: {
            current_page: 1,
            per_page: 5,
            total_objects: storageMapMeasurements.length,
            total_pages: Math.ceil(storageMapMeasurements.length / 5)
          },
          measurements: storageMapMeasurements.slice(
            (pagination.page - 1) * 5,
            (pagination.page - 1) * 5 + 5
          )
        }
      }
      const response = get(
        querySelector({
          url: "/measurements/search",
          options: {
            method: "POST",
            body: {
              q: {
                f: {
                  storage_id: storage.id,
                  ...(storage.measurements?.length && {
                    measurement_id: storage.measurements.join(",")
                  })
                }
              },
              fields: [
                "storage_id",
                "weight",
                "weight_density",
                "photo",
                "photo_optimized",
                "latitude",
                "comment",
                "longitude",
                "volume_unit",
                "photo_thumbnail",
                "volume",
                "coefficient",
                "wood_type_id",
                "log_length",
                "shipment_number",
                "measurement_type_id",
                "wood_quality_id",
                "assortment_id",
                "updated_at",
                "volume_formula",
                "arrival_date",
                "photo_file_name",
                "load_lines",
                "measurement_area",
                "device_id",
                "taken_at",
                "measured_at",
                "poor_detection"
              ],
              extra_fields: [
                "logs",
                "references",
                "load_json",
                "custom_fields",
                "logs_sheet"
              ],
              paginate: {
                per_page: 5,
                page: pagination.page
              }
            }
          }
        })
      )

      return {
        meta: response.meta,
        measurements: response.measurements.map(normalizeJson)
      }
    }
})

export const RowLoading = ({ height }) => (
  <tr>
    <td style={{ padding: 0 }} colSpan="8">
      <table
        className="bg-white text-center"
        style={{ marginTop: "-8px", width: "100%" }}
      >
        <tbody>
          <tr>
            <td style={{ height: height || "512px" }}>
              <Loading color={"dark"} text={t`Loading measurements...`} />
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
)

export default function MeasurementRowList({ storage }) {
  const [searchParams] = useSearchParams()
  const [storagePage, setStoragePage] = useRecoilState(
    storageMeasurementsPageState(storage.id)
  )
  const {
    measurements,
    meta: { total_pages }
  } = useRecoilValue(
    measurementsQuery({
      pagination: { page: storagePage },
      storage
    })
  )
  const [selectedMeasurements, setSelectedMeasurements] = useRecoilState(
    selectedMeasurementsState
  )
  const setShowMap = useSetRecoilState(showMapState)
  const [checkAll, setCheckAll] = useState(false)
  const setMarkers = useSetRecoilState(markersState)

  useEffect(() => {
    let selected = 0

    measurements
      .map($ => $.id)
      .forEach(id => {
        if (selectedMeasurements.find($ => $.id === id)) {
          selected = selected + 1
        }
      })

    setCheckAll(selected === measurements.length)
  }, [selectedMeasurements, measurements])

  useEffect(() => {
    if (searchParams.get("p")) {
      // setPage(Number(searchParams.get("p")))
      // const [page, setPage] = useState(searchParams.get("p") || storagePage || 1)
      setStoragePage(searchParams.get("p"))
    }
    // return () => {
    //   setSearchParams()
    // }
  }, [searchParams, setStoragePage])

  return (
    <tr>
      <td style={{ padding: 0 }} colSpan="8">
        <table style={{ marginTop: "-8px", width: "100%" }}>
          <thead>
            <tr className="bg-whitesmoke border-top border-bottom text-muted small">
              <td style={{ textAlignLast: "center" }} className="py-0">
                <input
                  type="checkbox"
                  checked={checkAll}
                  onChange={() => {
                    if (checkAll) {
                      setCheckAll(false)
                      setSelectedMeasurements(
                        selectedMeasurements.filter(
                          m => !measurements.find($ => $ !== m)
                        )
                      )
                    } else {
                      setCheckAll(true)

                      let newSelectedMeasurements = [...selectedMeasurements]
                      measurements.forEach(m => {
                        if (!newSelectedMeasurements.find($ => $.id === m.id)) {
                          newSelectedMeasurements.push(m)
                        }
                      })
                      setSelectedMeasurements(newSelectedMeasurements)
                    }
                  }}
                ></input>
              </td>
              <th>
                ID / <Trans>Measured on</Trans>
              </th>
              <th className="d-none d-xxl-table-cell">
                <Trans>Type</Trans>
              </th>
              <th className="text-center d-none d-xxl-table-cell">
                <Trans>Coordinates</Trans>
              </th>
              <th className="d-none d-xl-table-cell">
                <Trans>Wood</Trans>
              </th>
              <th className="d-none d-xl-table-cell">
                <Trans>Length</Trans>
              </th>
              <th className="d-none d-xl-table-cell">
                <Trans>Log count</Trans>
              </th>
              <th className="text-center">
                <Trans>Volume</Trans>
              </th>
            </tr>
          </thead>
          <Suspense>
            <tbody>
              {measurements.length ? (
                withMonths(measurements)
              ) : (
                <tr
                  style={{
                    background: "whitesmoke",
                    textAlign: "center",
                    color: "gray"
                  }}
                >
                  <td colSpan="8">
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="hover-dark me-2"
                    />
                    <Trans>No measurements</Trans>
                  </td>
                </tr>
              )}
            </tbody>
          </Suspense>
          <MeasurementsPagination />
        </table>
      </td>
    </tr>
  )

  function MeasurementsPagination() {
    let startPage = storagePage <= 2 ? 1 : storagePage - 1
    let endPage = storagePage >= total_pages - 1 ? total_pages : storagePage + 1

    if (endPage - startPage < 2) {
      if (storagePage <= 2) {
        endPage = Math.min(3, total_pages)
      } else {
        startPage = Math.max(total_pages - 2, 1)
      }
    }

    const pageItems = []
    for (let i = startPage; i <= endPage; i++) {
      pageItems.push(i)
    }
    return (
      total_pages > 1 && (
        <tfoot>
          <tr className="bg-light border-top">
            <td colSpan="8">
              <Pagination className="justify-content-center m-0 py-2">
                <PageItem
                  onClick={() => setStoragePage(1)}
                  disabled={storagePage === 1}
                >
                  <Trans>First</Trans>
                </PageItem>
                <PageItem
                  onClick={() => setStoragePage(storagePage - 1)}
                  disabled={storagePage === 1}
                >
                  <Trans>Previous</Trans>
                </PageItem>
                {pageItems.map((page, index) => (
                  <PageItem
                    onClick={() => setStoragePage(page)}
                    key={index}
                    active={storagePage === page}
                  >
                    {page}
                  </PageItem>
                ))}
                {/* <PageItem active title="Click to edit page number">
                  <span className="small">
                    <span
                      contentEditable
                      suppressContentEditableWarning={true}
                      onInput={event => {
                        const value = Number(event.target.textContent.trim())
                        if (value >= 1 && value <= total_pages) {
                          setTimeout(() => setStoragePage(value), 1000)
                        }
                      }}
                    >
                      {storagePage}
                    </span>
                    /{total_pages}
                  </span>
                </PageItem> */}
                <Pagination.Item
                  onClick={() => setStoragePage(storagePage + 1)}
                  disabled={total_pages === storagePage}
                  className="text-secondary"
                >
                  <Trans>Next</Trans>
                </Pagination.Item>
                <PageItem
                  onClick={() => setStoragePage(total_pages)}
                  disabled={total_pages < storagePage + 1}
                >
                  <Trans>Last</Trans>
                </PageItem>
              </Pagination>
            </td>
          </tr>
        </tfoot>
      )
    )
  }

  function withMonths(measurements) {
    let previousMonth = null
    return measurements.reduce((rows, measurement, index) => {
      let month = new Date(measurement.created_at).getMonth()
      let monthRow
      if (month !== previousMonth) {
        monthRow = (
          <tr key={month} className="bg-primary">
            <td colSpan="8" className="text-center fs-6 text-secondary p-0">
              <div className="d-flex justify-content-center">
                <div className="text-center">
                  {/* <div className="border border-0 translate-middle-y mx-2 border-bottom d-inline flex-grow-1"></div> */}
                  <small className="fw-medium text-light">
                    {new Intl.DateTimeFormat("en", {
                      month: "long",
                      year: "numeric"
                    }).format(new Date(measurement.created_at))}
                  </small>
                  {/* <div className="border border-0 translate-middle-y mx-2 border-bottom d-inline flex-grow-1"></div> */}
                </div>
              </div>
            </td>
          </tr>
        )
      }

      previousMonth = month

      return [
        ...rows,
        monthRow ?? undefined,
        <TimberMeasurementRow
          manual={measurement.type?.includes("Manual")}
          key={measurement.id}
          storage={storage}
          measurement={measurement}
          showOnMap={() => {
            setShowMap(true)
            setMarkers([
              <MeasurementMarker key={index} measurement={measurement} open />
            ])
            setTimeout(
              () => window.document.getElementById("map").scrollIntoView(),
              100
            )
          }}
        />
      ]
    }, [])
  }
}
