import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import { subscriptionItemsState } from "billing"
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"

export default function UpgradeNotification() {
  const navigate = useNavigate()
  const subscriptionItemsLoadable = useRecoilValueLoadable(
    subscriptionItemsState
  )
  const subscriptionItems = useRecoilValue(subscriptionItemsState)
  const notProOrEnterprise =
    subscriptionItems?.filter(
      item =>
        item.package_id === "pro_subscription" ||
        item.package_id === "enterprise_subscription"
    )?.length === 0
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (notProOrEnterprise && subscriptionItemsLoadable?.state !== "loading") {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [notProOrEnterprise, subscriptionItemsLoadable?.state])

  return show ? (
    <div className="d-flex align-items-center bg-warning py-1">
      <div className="flex-grow-1 text-center">
        <Button
          onClick={() => navigate("/billing")}
          variant="warning"
          style={{
            cursor: "pointer"
          }}
          className="fw-normal py-0 px-2 link-dark"
        >
          <span className="small fw-semibold">
            <Trans>Get more features with a subscription.</Trans>{" "}
            <u style={{ textDecorationThickness: 2 }}>
              <Trans>Learn more</Trans>
            </u>
          </span>
        </Button>
      </div>
      <Button
        onClick={() => setShow(false)}
        variant="warning"
        size="sm"
        className="px-1 py-0 me-3"
      >
        <FontAwesomeIcon icon={faTimes} className="opacity-50" />
      </Button>
    </div>
  ) : null
}
