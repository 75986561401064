import { t, Trans } from "@lingui/macro"
import useFetch from "common/hooks/useFetch"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import Spinner from "common/other/Spinner"
import { capitalize } from "lodash"
import { organizationPlanInterval } from "billing/AdditionalFeatures"
import { subscriptionItemsState } from "billing"
import {
  subscriptionCanceledSelector,
  subscriptionExistsSelector,
  subscriptionStatusSelector
} from "billing/TimbeterPro"
import { useState } from "react"
import { Alert, Badge, Button, Card, Col, Form, Row } from "react-bootstrap"
import { atom, selector, useRecoilValue } from "recoil"
import { querySelector } from "common/recoil/selectors"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleExclamation, faClock } from "@fortawesome/free-solid-svg-icons"

export const plansSelector = selector({
  key: "plans",
  get: ({ get }) =>
    get(subscriptionItemsState)?.filter($ => $.purchase_type === "plan")
})

export const featuresSelector = selector({
  key: "features",
  get: ({ get }) =>
    get(subscriptionItemsState)?.filter($ => $.purchase_type === "bundle")
})

export const invoicesQuery = selector({
  key: "invoicesQuery",
  get: async ({ get }) => {
    try {
      const invoices = await get(querySelector("/subscription/invoices"))
      return invoices?.length > 0 ? invoices : []
    } catch {
      return []
    }
  }
})

export const invoicesState = atom({
  key: "invoices",
  default: invoicesQuery
})

export default function Overview() {
  const organization = useRecoilValue(currentOrganizationState)
  const fetch = useFetch()
  const [loading, setLoading] = useState(false)
  const orgPlanInterval = useRecoilValue(organizationPlanInterval)
  const subscriptionExists = useRecoilValue(subscriptionExistsSelector)
  const subscriptionStatus = useRecoilValue(subscriptionStatusSelector)
  const subscriptionCanceled = useRecoilValue(subscriptionCanceledSelector)
  const navigate = useNavigate()
  const paidUntil = organization.paid_until
    ? new Date(organization?.paid_until).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric"
      })
    : "–"

  const subscriptionStatusColorMap = {
    active: "success",
    canceled: "secondary",
    trialing: "info"
  }

  function customerPortal() {
    setLoading(true)
    fetch("/customer_portal", {
      method: "POST"
    }).then(response => {
      window.location.assign(response.portal_url)
    })
  }
  const collectionMethod =
    organization.collection_method === "charge_automatically"
      ? "Automatic"
      : organization.collection_method === "send_invoice"
      ? "Manual"
      : "–"

  return subscriptionExists ? (
    <SubscriptionOverview />
  ) : (
    <MeasurementsOverview />
  )

  function MeasurementsOverview() {
    return (
      <Card>
        <Card.Body className="row">
          <Card.Title className="col col-12">
            <Trans>Remaining measurements</Trans>
            <Button
              variant="dark"
              className="rounded-pill px-3 ms-3"
              onClick={() => navigate("/billing/measurements")}
              size="sm"
            >
              <Trans>Buy more</Trans>
            </Button>
          </Card.Title>

          <Form.Group as={Col} xs="auto">
            <Form.Label>
              <Trans>Available measurements</Trans>
            </Form.Label>
            <Form.Control
              readOnly
              disabled
              defaultValue={organization?.remaining_measurements ?? 0}
            />
          </Form.Group>
          {organization?.remaining_measurements > 0 ? (
            <Form.Group as={Col} xs="auto">
              <Form.Label>
                <Trans>Active until</Trans>
              </Form.Label>
              <Form.Control
                readOnly
                disabled
                defaultValue={
                  organization.paid_until
                    ? new Date(organization?.paid_until).toLocaleDateString(
                        undefined,
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric"
                        }
                      )
                    : "–"
                }
              />
            </Form.Group>
          ) : null}
        </Card.Body>
      </Card>
    )
  }

  function SubscriptionOverview() {
    return (
      <Col xs="12" xxl="6">
        <Alert variant="warning">
          <Alert.Heading>
            <FontAwesomeIcon icon={faCircleExclamation} className="me-2" />
            EU VAT code for businesses
          </Alert.Heading>
          <p>
            <Trans>
              If you are a business in EU please attach your EU VAT code to your
              organization{" "}
              <Alert.Link href="/settings/organization">here.</Alert.Link>
            </Trans>
          </p>
        </Alert>
        <Card>
          <Card.Body>
            <div className="d-flex align-items-center">
              <Card.Title className="flex-grow-1 mb-0">
                <span>
                  <Trans>Subscription</Trans>
                </span>
                <Badge
                  className="fw-semibold ms-2 mb-2"
                  bg={subscriptionStatusColorMap[subscriptionStatus]}
                >
                  {subscriptionStatus === "active" ? t`Active` : null}
                  {subscriptionStatus === "trialing"
                    ? t`Trial ends` + ` ${paidUntil}`
                    : null}
                  {subscriptionStatus === "canceled" ? t`Canceled` : null}
                </Badge>
                {subscriptionCanceled && (
                  <Badge className="fw-semibold mb-2 ms-2" bg="secondary">
                    <FontAwesomeIcon icon={faClock} className="me-1" />
                    {t`Cancels ` + ` ${paidUntil}`}
                  </Badge>
                )}
              </Card.Title>
            </div>
            <Row>
              <Form.Group className="mb-2 col-6 col-xl-4">
                <Form.Label>
                  <Trans>Payments</Trans>
                </Form.Label>
                <Form.Control
                  readOnly
                  disabled
                  defaultValue={collectionMethod}
                />
              </Form.Group>
              <Form.Group className="mb-2 col-6 col-xl-4">
                <Form.Label>
                  <Trans>Billing cycle</Trans>
                </Form.Label>
                <Form.Control
                  readOnly
                  disabled
                  defaultValue={capitalize(orgPlanInterval)}
                />
              </Form.Group>
              <Form.Group className="mb-2 col-6 col-xl-4">
                <Form.Label>
                  {collectionMethod === "Automatic" ? (
                    <Trans>Next billing date</Trans>
                  ) : (
                    <Trans>Next invoice due date</Trans>
                  )}
                </Form.Label>
                <Form.Control
                  readOnly
                  disabled
                  defaultValue={
                    subscriptionStatus === "active" ? paidUntil : "–"
                  }
                />
                {collectionMethod === "Manual" ? (
                  <Form.Text>
                    <Trans>
                      Invoices are sent via email 10 days before the due date
                    </Trans>
                  </Form.Text>
                ) : null}
              </Form.Group>
            </Row>
            {/* <Form.Group>
                <Form.Label>
                  <Trans>Payment method</Trans>
                </Form.Label>
                <Form.Check
                  checked
                  readOnly
                  label={
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip">
                          <Trans>
                            Timbeter uses Stripe for online payment processing
                          </Trans>
                        </Tooltip>
                      }
                    >
                      <div>
                        <FontAwesomeIcon
                          size="xl"
                          icon={faCcStripe}
                          className="me-2"
                          style={{ color: "#635bff" }}
                        />
                      </div>
                    </OverlayTrigger>
                  }
                  type="radio"
                />
              </Form.Group> */}
            <div className="mt-4">
              <Button
                disabled={loading}
                className="w-100 position-relative rounded-pill "
                onClick={customerPortal}
              >
                <span className="position-absolute start-0 align-items-center ps-3 opacity-25">
                  {loading ? <Spinner /> : null}
                </span>
                <span>
                  <Trans>Manage subscription</Trans>
                </span>
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    )
  }
}
